// _app.tsx
import Head from 'next/head'
import Script from 'next/script'
import type { AppProps, NextWebVitalsMetric } from 'next/app'
import { config } from '@fortawesome/fontawesome-svg-core'

import '../styles/main.css'
import ErrorBoundary from '@components/error-boundary'
import useCustomScroll from '@hooks/useCustomScroll'
import ClientWrapper from '@components/client-wrapper'
import { type AppConfigType, getAppConfig } from '@lib/services/aws-app-config-service'
import ChatAnalyticsTracker from '@components/chat-analytics-tracker'

// Prevent font awesome from creating a style element; instead
// we add it via PostCSS in styles/main.css
config.autoAddCss = false

interface ExtendedAppProps extends AppProps {
  readonly appConfig: AppConfigType
}

function SharpComApp({ Component, pageProps, appConfig }: ExtendedAppProps) {
  // TODO: This needs to be refactord and removed.
  useCustomScroll()

  return (
    <>
      <Head>
        {/* Add key to meta tags so we can overwrite instead of duplicate if needed. */}
        <meta key="charSet" charSet="UTF-8" />
        <meta key="viewport" name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta key="uaCompatible" httpEquiv="X-UA-Compatible" content="ie=edge" />
      </Head>
      {/* Schema App */}
      <Script id="schema-app" strategy="afterInteractive">
        {`
              window.schema_highlighter={accountId: "SharpHealthCare", outputCache: true}
          `}
      </Script>
      <Script src="https://cdn.schemaapp.com/javascript/highlight.js"></Script>
      {/* End Schema App */}
      <ErrorBoundary>
        <ClientWrapper appConfig={appConfig}>
          <ChatAnalyticsTracker />
          <Component {...pageProps} />
        </ClientWrapper>
      </ErrorBoundary>
    </>
  )
}

SharpComApp.getInitialProps = async () => {
  const appConfig = await getAppConfig()
  return { appConfig }
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  console.log(metric)
}

export default SharpComApp
