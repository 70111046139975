import type { GetLatestConfigurationCommandOutput } from '@aws-sdk/client-appconfigdata'
import {
  AppConfigDataClient,
  GetLatestConfigurationCommand,
  StartConfigurationSessionCommand,
} from '@aws-sdk/client-appconfigdata'
import { Mutex } from 'async-mutex'

export interface AppConfigType {
  configs: ConfigsType
  flags: FeatureFlagsType
}

export type FeatureFlagsType = {
  responsiveImageFix: boolean
  chat: boolean
}

export type ConfigEpicType = {
  bookingApptURL: string
}

export type ConfigMyChartLoginType = {
  baseUrl: string
  environment: string
}

export type ConfigsType = {
  algolia: ConfigAlgoliaType
  myChartLogin: ConfigMyChartLoginType
  epic: ConfigEpicType
}

export type ConfigAlgoliaType = {
  algoliaKey: string
  globalIndexName: string
  providersIndexName: string
  locationsIndexName: string
}

export const localConfig: Readonly<AppConfigType> = {
  configs: {
    algolia: {
      algoliaKey: '2d8a941526432286d71a0682e31858a0',
      globalIndexName: 'crawler_sharp_com',
      providersIndexName: 'providers_nonprod',
      locationsIndexName: 'places_nonprod',
    },
    myChartLogin: {
      baseUrl: 'https://mychart-np.et1275.epichosted.com',
      environment: 'MyChartTST',
      // Production settings
      // baseUrl: 'https://app.sharp.com',
      // environment: '',
    },
    epic: {
      bookingApptURL: 'https://portal-stg.aws-nonprod.sharp.com/appointments/',
    },
  },
  flags: {
    responsiveImageFix: true,
    chat: true,
  },
}

// Cache variables
let isInitialized: boolean = false
let client: AppConfigDataClient
let config: AppConfigType | undefined
let token: string | undefined
let nextPollTs: number = 0
const mutex = new Mutex()

const init = async () => {
  const startConfigurationSessionOptions = {
    ApplicationIdentifier: process.env.APP_CONFIG_APP_ID,
    ConfigurationProfileIdentifier: process.env.APP_CONFIG_PROFILE_ID,
    EnvironmentIdentifier: process.env.APP_CONFIG_ENV_ID,
    RequiredMinimumPollIntervalInSeconds: 120, // only download a new configuration every 60 seconds
  }

  client = new AppConfigDataClient({ region: 'us-west-2' })
  const command = new StartConfigurationSessionCommand(startConfigurationSessionOptions)
  const getToken = await client.send(command)
  token = getToken.InitialConfigurationToken // costs $0.0008
  isInitialized = true
}

/**
 *
 * Get appConfiguration from AWS.
 * For Local development, you will need to add/update APP_CONFIG_SOURCE variable to "local" in the ENV file!
 *
 * @example const result = await getAppConfig();
 * @returns {Promise<AppConfigType>} Returns an object type AppConfigType
 */
export const getAppConfig: () => Promise<AppConfigType> = async () => {
  // Local development escape hatch - Calling appconfig during local environment will cost money
  if (process.env.APP_CONFIG_SOURCE === 'local') {
    console.log('getAppConfig: using local environment')
    return localConfig
  }

  // AppConfig logic
  // Acquire a lock to prevent edge case of multiple calls before init
  const release = await mutex.acquire()
  try {
    // Guard for client init
    if (!isInitialized) {
      console.log('getAppConfig: no client found; initializing')
      await init()
    }

    // Get Latest config if beyond poll interval
    if (new Date().getTime() > nextPollTs) {
      const getLatestConfig = new GetLatestConfigurationCommand({
        ConfigurationToken: token,
      })

      const configs: GetLatestConfigurationCommandOutput = await client.send(getLatestConfig)

      token = configs.NextPollConfigurationToken // costs $0.0000002
      nextPollTs = new Date().getTime() + (configs.NextPollIntervalInSeconds ?? 0) * 1000

      if (configs?.Configuration?.buffer.byteLength) {
        const result = Buffer.from(configs.Configuration.buffer).toString()
        console.log('getAppConfig: new config received\n', result)
        config = JSON.parse(result)
      }
    }
  } catch (error) {
    console.log('getAppConfig: Error:', error)
  }

  // Release lock
  release()

  if (!config) {
    throw new Error('getAppConfig: Failed to fetch config')
  }

  // Finally return config
  return config
}

const methods = {
  localConfig,
  getAppConfig,
}

export default methods
