'use client'
import React, { useState, useCallback, Component } from 'react'

import ErrorFallback from '@components/error-fallback'

export const useAsyncError = () => {
  const [_, setError] = useState()

  return useCallback(
    (e: Error) => {
      setError(() => {
        throw e
      })
    },
    [setError]
  )
}

interface ErrorBoundaryState {
  hasError?: boolean
  error?: Error
  children?: React.ReactNode
}

interface ErrorBoundaryProps {
  hasError?: boolean
  error?: Error
}

export default class ErrorBoundary extends Component<ErrorBoundaryState, ErrorBoundaryProps> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = {
      hasError: false,
      error: new Error(),
    }
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
      error,
    }
  }

  componentDidCatch(error: Error, errorInfo: object) {
    // log the error to an error reporting service
    // console.log(`error: ${error}, errorInfo: ${JSON.stringify(errorInfo)}`);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorFallback error={this.state.error} />
    }
    return this.props.children
  }
}
